.__clever-ui__match-students-table__e4d11 {
  .td-sisid {
    max-width: 120px;
    width: 120px;
  }

  .td-matching {
    min-width: 200px;
    max-width: 450px;
  }
}
