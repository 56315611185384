.__clever-ui__table__38f79 .clever-table {
  thead {
    @apply bg-dusty-black-300 text-white;
  }
}

.__clever-ui__table__38f79 .clever-table tr {
  th {
    @apply border-r border-white px-2 py-5 font-semibold;

    &:last-of-type {
      @apply border-none;
    }
  }

  td {
    @apply border-dusty-black-50 border p-2;

    div {
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
    }
  }

  .form-select {
    .scroller > ul {
      /* makes the dropdown open to the left */
      right: 0;
    }
  }
}
