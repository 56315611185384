/* 'active' and 'exiting' are classes applied by the 3rd party addon ember-cli-flash. Tricky to do this with tailwind */
.flash-box-animate-in-out {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);

  &.active {
    transform: translateX(0);
  }

  &.exiting.active {
    transform: translateX(100%);
  }
}

.flash-box-style-links {
  a {
    font-weight: 600;
    color: $dusty-black-300;
    text-decoration: underline;
  }
}
