.stacked-buttons {
  display: inline-flex;

  @apply flex-col items-start justify-center;

  > button,
  > a {
    flex: 0 1 auto;
    width: 100%;

    &:not(:first-child, :last-child) {
      border-radius: 0;
    }

    &:first-child:not(:last-child) {
      border-radius: 4px 4px 0 0;
    }

    &:last-child:not(:first-child) {
      border-radius: 0 0 4px 4px;
    }
  }
}
