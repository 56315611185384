$button-font-size: 1rem;
$button-line-height: 1.5;
$button-font-family: "Open Sans", sans-serif;
$button-padding: 0.5rem 1.25rem;
$small-button-padding: 3px 8px;

$white-btn-bg: $white;
$white-btn-border: $dusty-black-100;
$white-btn-bg-hover: $dusty-black-100;
$white-btn-border-hover: $dusty-black-200;

$blue-btn-bg: $oceany-blue-300;
$blue-btn-bg-active: $oceany-blue-325;
$blue-btn-border: $oceany-blue-200;
$blue-btn-bg-hover: $oceany-blue-400;
$blue-btn-border-hover: $oceany-blue-500;

$cyan-btn-bg: #5bc0de;
$cyan-btn-border: #4bbadb;
$cyan-btn-bg-hover: #3bb4d8;
$cyan-btn-border-hover: #2baed5;

$orange-btn-bg: #f0ad4e;
$orange-btn-border: #eea53c;
$orange-btn-bg-hover: #ec9d2a;
$orange-btn-border-hover: #ea9518;

$green-btn-bg: $ms-green-300;
$green-btn-bg-active: #389139;
$green-btn-border: $ms-green-200;
$green-btn-bg-hover: $ms-green-350;
$green-btn-border-hover: $ms-green-400;

$red-btn-bg: #d9534f;
$red-btn-border: #d43f3a;
$red-btn-bg-hover: #c9302c;
$red-btn-border-hover: #ac2925;

.__ui-button__fd7ee {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: $button-padding;
  color: $dusty-black-300;
  font: normal $button-font-size/$button-line-height $button-font-family;
  user-select: none;
  text-decoration: none;
  appearance: none;

  &:focus,
  &:active {
    outline: none;
    box-shadow: 0 0 0 2px rgba($oceany-blue-300, 0.75);
    z-index: 1;

    .maths & {
      box-shadow: 0 0 0 2px rgba($ms-green-300, 0.75);
    }
  }

  &:hover,
  &:focus {
    color: currentcolor;
    text-decoration: none;
    transition: all 0.5s ease 0s;
  }

  &.disabled,
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.65;
    box-shadow: none;

    .maths & {
      box-shadow: none;
    }
  }

  @media print {
    display: none !important;
  }
}

.__ui-button__fd7ee.ui-btn-small {
  @apply rounded-sm;

  padding: $small-button-padding;
}

.__ui-button__fd7ee.regular {
  color: $white;
  background-color: $blue-btn-bg;
  border-color: $blue-btn-border;

  &.disabled,
  &[disabled] {
    &:hover,
    &:focus,
    &:active {
      background-color: $blue-btn-bg;
      border-color: $blue-btn-border;
    }
  }

  &.active {
    background-color: $blue-btn-bg-active;

    &:hover,
    &:focus {
      border-color: $blue-btn-border-hover;
    }
  }

  &:hover,
  &:focus,
  &:active,
  &:active:hover {
    color: $white;
    background-color: $blue-btn-bg-hover;
    border-color: $blue-btn-border;
  }

  .maths & {
    background-color: $green-btn-bg;
    border-color: $green-btn-border;

    &.active {
      background-color: $green-btn-bg-active;

      &:hover,
      &:focus {
        background-color: $green-btn-bg-active;
        border-color: $green-btn-border-hover;
      }
    }

    &:hover,
    &:focus,
    &:active,
    &:active:hover {
      color: $white;
      background-color: $green-btn-bg-active;
      border-color: $green-btn-border-hover;
    }

    &.disabled,
    &[disabled] {
      &:hover,
      &:focus,
      &:active,
      &.active {
        background-color: $green-btn-bg;
        border-color: $green-btn-border;
      }
    }
  }
}

.__ui-button__fd7ee.green {
  color: $white;
  background-color: $green-btn-bg;
  border-color: $green-btn-border;

  &.disabled,
  &[disabled] & {
    &:hover,
    &:focus,
    &:active {
      background-color: $green-btn-bg;
      border-color: $green-btn-border;
    }
  }

  &:hover,
  &:focus,
  &:active {
    color: $white;
    background-color: $green-btn-bg-hover;
    border-color: $green-btn-border-hover;
  }
}

.__ui-button__fd7ee.cyan {
  color: $white;
  background-color: $cyan-btn-bg;
  border-color: $cyan-btn-border;

  &.disabled,
  &[disabled] {
    &:hover,
    &:focus,
    &:active {
      background-color: $cyan-btn-bg;
      border-color: $cyan-btn-border;
    }
  }

  &:hover,
  &:focus,
  &:active {
    color: $white;
    background-color: $cyan-btn-bg-hover;
    border-color: $cyan-btn-border-hover;
  }
}

.__ui-button__fd7ee.orange {
  color: $white;
  background-color: $orange-btn-bg;
  border-color: $orange-btn-border;

  &.disabled,
  &[disabled] & {
    &:hover,
    &:focus,
    &:active {
      background-color: $orange-btn-bg;
      border-color: $orange-btn-border;
    }
  }

  &:hover,
  &:focus,
  &:active {
    color: $white;
    background-color: $orange-btn-bg-hover;
    border-color: $orange-btn-border-hover;
  }
}

.__ui-button__fd7ee.red {
  color: $white;
  background-color: $red-btn-bg;
  border-color: $red-btn-border;

  &.disabled,
  &[disabled] {
    &:hover,
    &:focus,
    &:active {
      background-color: $red-btn-bg;
      border-color: $red-btn-border;
    }
  }

  &:hover,
  &:focus,
  &:active {
    color: $white;
    background-color: $red-btn-bg-hover;
    border-color: $red-btn-border-hover;
  }
}

.__ui-button__fd7ee.muted {
  color: $dusty-black-300;
  background-color: $white-btn-bg;
  border-color: $white-btn-border;

  &:hover,
  &:focus {
    color: $dusty-black-300;
    background-color: $white-btn-bg-hover;
    border-color: $white-btn-border-hover;
  }

  &:active,
  &.active {
    color: $white;
    background-color: $blue-btn-bg;
    border-color: $blue-btn-border;

    .maths & {
      background-color: $forest-green-300;
      border-color: $ms-green-300;
    }
  }

  &.disabled,
  &[disabled] {
    &:hover,
    &:focus,
    &:active,
    &.active {
      color: $dusty-black-300;
      background-color: $white-btn-bg;
      border-color: $white-btn-border;
    }
  }
}
