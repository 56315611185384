@import url("https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100..900;1,100..900&display=swap");

html,
body {
  font-family: "Inter", "Helvetica Neue", arial, sans-serif;
  color: $neutral-300;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
  margin-top: 0;
  line-height: 1.1;

  small {
    font-size: 65%;
    font-weight: 400;
    line-height: 1;
    color: $neutral-250;
  }
}

h1,
.h1 {
  font-weight: 200;
  font-size: 3em;
  color: $neutral-400;

  @media print {
    text-align: center;
  }
}

h2,
.h2 {
  font-size: 1.25em;
  color: $neutral-400;
  font-weight: 700;
}

h3,
.h3 {
  font-size: 1em;
  font-weight: 700;
  margin-top: 20px;
}

h4,
.h4 {
  font-size: 1em;
  font-weight: 200;
}

h5,
.h5 {
  font-size: 1em;
  font-weight: 700;
}

p {
  font-size: 0.8em;
  font-weight: 400;
}
