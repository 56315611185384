.__alert__baa2f {
  a,
  a:hover,
  a:focus,
  a:visited {
    text-decoration: underline;
    color: $dusty-black-300;
  }

  .close {
    align-self: flex-start;
    font-size: 1.5em;
    font-weight: bold;
    line-height: 1;
    color: $black;
    text-shadow: 0 1px 0 $white;
    opacity: 0.2;

    &:hover,
    &:focus {
      color: $black;
      text-decoration: none;
      cursor: pointer;
      opacity: 0.5;
    }
  }
}

.__alert__baa2f.info {
  background-color: $oceany-blue-50;
  border-color: $oceany-blue-300;

  .icon {
    color: $oceany-blue-325;
  }
}

.__alert__baa2f.caution {
  background-color: $juicy-orange-50;
  border-color: $juicy-orange-300;

  .icon {
    color: $mid-orange-300;
  }
}

.__alert__baa2f.positive {
  background-color: $forest-green-50;
  border-color: $forest-green-300;

  .icon {
    color: $forest-green-300;
  }
}

.__alert__baa2f.critical {
  background-color: $watermelony-red-50;
  border-color: $watermelony-red-150;

  .icon {
    color: $watermelony-red-300;
  }
}
