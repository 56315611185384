.__clever-ui__match-teachers-table__3052f {
  .td-sisid {
    max-width: 120px;
    width: 120px;
  }

  .td-matching {
    min-width: 200px;
    max-width: 500px;
  }
}
