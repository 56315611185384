.__clever-ui__workflow-bar__85498 {
  display: flex;
  max-width: 900px;

  @apply mx-auto my-0 items-baseline;

  a.step-link {
    color: inherit;

    &.theme-blue.step-active {
      @apply bg-oceany-blue-300 font-bold text-white;
    }

    &.theme-green.step-active {
      @apply bg-ms-green-300 font-bold text-white;
    }

    &.disabled {
      @apply bg-dusty-black-100 cursor-not-allowed;
    }

    &:last-of-type {
      @apply border-none;
    }

    &:not(.step-active) {
      .done-icon {
        @apply text-forest-green-350;
      }
    }
  }
}
