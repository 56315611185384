$line-height-computed: 1;
$panel-bg: $white;
$panel-border-radius: 5px;
$panel-header-padding: 15px 25px;
$table-border-color: none;

$panel-muted-border: $dusty-black-50;
$panel-muted-text: $dusty-black-250;
$panel-muted-header-bg: $dusty-black-100;

$panel-blue-border: transparent;
$panel-blue-text: $oceany-blue-400;
$panel-blue-header-bg: $oceany-blue-100;

$panel-green-border: transparent;
$panel-green-text: $ms-green-400;
$panel-green-header-bg: $ms-green-100;

.__panel__136a8 {
  margin-bottom: 22px;
  background-color: $panel-bg;
  border: 1px solid transparent;
  border-radius: $panel-border-radius;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 5%);
  transition: border 0.5s;

  @media print {
    border-color: $panel-muted-border !important;
    border-radius: 0;
    box-shadow: none !important;
    margin-bottom: 5px;
    page-break-inside: avoid;

    /* To fix Safari bug */
    display: inline-block;
    vertical-align: top;
    width: 100%;
  }

  panel-header {
    padding: $panel-header-padding;
    border-bottom: 1px solid transparent;
    border-top-left-radius: calc($panel-border-radius - 2px);
    border-top-right-radius: calc($panel-border-radius - 2px);

    h2 {
      font-size: 1rem;
      font-weight: bold;
      margin: 0;
    }
  }

  panel-body {
    @media print {
      padding: 5mm !important;
    }

    p {
      font-size: 1em;
    }
  }

  &.theme-default {
    border-color: $panel-muted-border;

    panel-header {
      background-color: $panel-muted-header-bg;
      border-color: $panel-muted-border;

      h2 {
        color: $panel-muted-text;
      }
    }

    &.active {
      border: 3px solid $panel-muted-text;

      &::before {
        border-top-color: $panel-muted-text;
      }
    }
  }

  &.theme-blue {
    border-color: $panel-blue-border;

    panel-header {
      background-color: $panel-blue-header-bg;
      border-color: $panel-blue-border;

      h2 {
        color: $panel-blue-text;
      }
    }

    &.active {
      border: 3px solid $panel-blue-text;

      &::before {
        border-top-color: $panel-blue-text;
      }
    }
  }

  &.theme-green {
    border-color: $panel-green-border;

    panel-header {
      background-color: $panel-green-header-bg;
      border-color: $panel-green-border;

      h2 {
        color: $panel-green-text;
      }
    }

    &.active {
      border: 3px solid $panel-green-text;

      &::before {
        border-top-color: $panel-green-text;
      }
    }
  }
}

.__panel__136a8.active {
  border: 3px solid transparent;
  position: relative;

  &::after,
  &::before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &::after {
    border-color: transparent;
    border-top-color: $white;
    border-width: 30px;
    margin-left: -30px;
  }

  &::before {
    border-color: transparent;
    border-top-color: transparent;
    border-width: 34px;
    margin-left: -34px;
  }
}
