/* .highcharts-container is a class applied by highcharts.js to the root chart element */
.highcharts-container {
  tspan,
  text,
  h1,
  h2,
  h3,
  h4,
  p,
  strong {
    @apply font-body;
  }

  .highcharts-title {
    @apply font-body;

    font-weight: bold;
    font-size: 14px !important;

    @media print {
      tspan {
        font-weight: bold !important;
      }
    }
  }

  .highcharts-legend-item {
    tspan {
      @apply font-body;
    }

    text {
      font-weight: normal !important;
      fill: $dusty-black-500 !important;
    }
  }

  .highcharts-axis-labels text {
    color: $dusty-black-500 !important;
  }

  .highcharts-data-labels text {
    color: $dusty-black-500 !important;
  }

  .highcharts-data-label text tspan {
    @media print {
      font-weight: bold !important;
    }
  }

  .highcharts-tooltip {
    @media print {
      display: none !important;
    }
  }
}
