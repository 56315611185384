/* Main print styles */
@media print {
  @page {
    size: a4 landscape; /* Only works in chrome, 210mm x 297mm */
    margin: 10mm; /* this affects the margin in the printer settings */
  }

  .fa-sort,
  .fa-sort-down,
  .fa-sort-up {
    display: none !important;
  }

  .detailed-chart-wrapper {
    page-break-after: auto;
    page-break-inside: avoid;
  }

  .font-bold,
  strong {
    font-weight: bold !important;
  }

  a {
    color: black !important;
  }
}
