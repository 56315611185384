/* easy to override default */
thead {
  text-align: left;
}

/* older, simple table style; dark header, some cell padding */
.disco-table {
  thead tr {
    th {
      font-weight: 500;
      text-align: center;
      color: $white;
      vertical-align: middle;
      background-color: $dusty-black-400;
      border-left: 1px solid $white;
      border-right: 1px solid $white;

      a {
        color: $white;
        cursor: pointer;
      }
    }
  }

  thead tr:last-of-type th {
    background-color: $dusty-black-300;
  }

  th,
  td {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;

    &.td-checkbox {
      text-align: center;
    }
  }
}

@media print {
  .disco-table thead > tr > th,
  .disco-table tbody > tr > td {
    border: 1px solid $black;
    background: transparent !important;
    color: $black !important;
  }
}

/*
Typical usage. This will get you pixel perfect borders and colours.
header: class="table-box table-box-header bg-ms-green-300"
cells: class="table-box table-box-cell bg-ms-green-100"
footer cells: class="table-footer-cell"
*/

.table-box {
  @apply border-l border-t border-white;

  /* Undo the top & left borders. If the user wants an outside border, they'll add it to the table element. */
  @media not print {
    &:first-child {
      @apply border-l-0;
    }

    thead tr:first-child & {
      @apply border-t-0;
    }
  }

  /* Surround all cells with a black border, use black text colour and remove any background color */
  @apply print:border print:border-black;
  @apply print:overflow-hidden;
  @apply print:bg-transparent !important;
  @apply print:table-cell;
  @apply print:text-black !important;
}

.table-box-header {
  @apply p-2 font-semibold;
  @apply print:p-1;
}

.table-box-cell {
  @apply p-2;
  @apply print:p-1;
}

.table-footer-cell {
  @apply p-2 font-semibold;
  @apply print:overflow-hidden print:border print:border-black print:bg-transparent print:p-1;
}

.table-box-header,
.table-box-header span,
.table-footer-cell,
.table-footer-cell span {
  @media print {
    font-weight: bold !important;
  }
}

@media print {
  table {
    display: table;
    table-layout: fixed;

    /* To fix a Firefox bug where it was cutting off the border */
    width: calc(100% - 4px) !important;
    margin: 2px;

    /* Stops the footer repeating across page breaks */
    tfoot {
      display: table-row-group;
    }
  }
}
