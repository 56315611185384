body {
  /* main content shouldnt overflow, but we need this here to ensure tooltips don't cause the page to overflow/scroll */
  overflow: hidden;

  @media print {
    display: block;
    margin-bottom: 40px;
    overflow: visible;
  }
}

a:hover {
  text-decoration: none;
}

/* Layout */

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 10px;
}

p {
  margin: 0 0 11px;
}

form {
  button {
    padding: 7px 20px;

    svg {
      margin-right: 7px;
    }
  }
}

.nav > li > a:hover,
.nav > li > a:focus {
  background-color: transparent;
  color: inherit;
}
