.form-components-form-select {
  position: relative;
  max-width: 100%;

  .scroller {
    min-width: 100%;
    background-color: $white;
    position: absolute;
    left: 0;
    padding-left: 0;
    list-style: none;
    z-index: 802;
    max-height: 400px;
    top: calc(100% + 4px);
    border-top: none;

    .search {
      position: sticky;
      top: 0;
      z-index: 813;
      width: 100%;
      margin: 0;
      padding: 8px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background-color: $white;

      input {
        width: 100%;
        max-width: 100%;
        background-color: $dusty-black-50;
        border: none;
        height: 26px;
        border-radius: 15px;
        padding-left: 10px;
        outline: none;
        font-style: italic;
        font-size: 0.8em;
      }

      svg {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    > ul {
      margin-bottom: 30px;
      min-width: 100%;
      position: absolute;
      overflow: auto;
      /* Define a max-height based on viewport size, to help with formselect's used in modals on smaller screens */
      max-height: 35vh;
      background-color: $white;
      border: 1px solid $dusty-black-100;
      border-radius: 5px;

      box-shadow: 0 0 2px $dusty-black-100;

      &::-webkit-scrollbar {
        width: 5px;
        border-radius: 2px;
        border: 2px solid $dusty-black-100;
      }

      &::-webkit-scrollbar-thumb {
        background: $dusty-black-200;
        margin-right: 5px;
        border-radius: 5px;
      }

      > li:hover {
        background-color: $dusty-black-50;
      }

      li.search:hover {
        background-color: $white;
      }
    }

    li {
      text-align: left;
      cursor: pointer;
      padding: 8px 16px;
      white-space: nowrap;
      border-bottom: 1px solid $dusty-black-100;

      &.default {
        border-bottom-width: 3px;
      }

      &:last-child {
        border: none;
      }
    }

    /* make sure to check the option component for these usages */
    .selected,
    .pre-selected {
      background-color: $dusty-black-50;
    }

    .child {
      padding: 0;
    }

    strong {
      display: block;
      font-weight: normal;
      padding: 10px 16px;

      &:hover {
        background-color: $dusty-black-100;
      }
    }

    /* NOTE: .flex is given in option string templates.......... */
    ul li .flex {
      display: flex;
      flex-direction: row;

      > div:first-of-type {
        flex-grow: 10;
      }

      > div:last-of-type {
        text-align: right;
        flex-grow: 2;

        .svg-inline--fa {
          color: $medal-silver;
        }

        span {
          width: 25px;
          text-align: right;
          display: inline-block;
        }
      }
    }

    ul ul li {
      border-bottom: 0;

      &:hover {
        background-color: $dusty-black-100;
      }
    }

    /* 2nd level item indent */
    ul li ul li {
      padding-left: 40px;
    }
  }

  small {
    border-radius: 3px;
    padding: 3px 8px;
    margin-left: -8px;
    margin-right: 5px;
    background-color: $white;
    position: relative;
    z-index: 821;
    transform: translate3d(0, 0, 0);
    display: block;
    width: auto;
    float: left;
  }

  .dropdown-overlay {
    background-color: $white;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 800;
    cursor: auto;
  }
}

.form-components-form-select-button {
  min-width: 100px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.scroller-opened {
    /* dropdown-overlay z-index is 800, need the button higher than that so that the reset icon can be clicked T_T */
    position: relative;
    z-index: 803;
  }
}
