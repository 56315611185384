.__clever-ui__modal__8e4af {
  &.custom-modal {
    box-sizing: border-box;
    font-size: 1rem;
    transition: box-shadow 0.2s;
    padding: 0 0 10px;
    margin-bottom: 10px;

    &:active {
      box-shadow: 0 0 1px $dusty-black-200;
    }

    &.large {
      min-width: 600px;
      max-width: 900px;

      .body {
        max-width: 900px;
      }
    }

    &.medium {
      min-width: 600px;
      max-width: 650px;

      .body {
        max-width: 650px;
      }
    }

    .header {
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      background-color: $oceany-blue-100;

      h2 {
        margin: 0;
        padding: 20px 30px;
        text-align: center;
        color: $dusty-black-300;
        font-weight: bold;
        font-size: 14px;
      }
    }

    &.maths {
      .header {
        background-color: $ms-green-100;
      }
    }

    &.warning,
    &.warning.maths {
      .header {
        background-color: $juicy-orange-300;
        position: relative;
        padding-top: 20px;

        h2 {
          text-align: center;
          color: $white;
        }

        .fa-circle-info {
          line-height: 16px;
          position: absolute;
          top: 15px;
          left: 50%;
          color: $white;
          background-color: transparent;
          transform: translateX(-50%);
          font-size: 1.4rem;
        }
      }
    }

    .sk-wave {
      margin-bottom: 10px;
    }

    .field {
      margin-bottom: 15px;

      &:last-of-type {
        margin: 0;
      }
    }

    .flex {
      display: flex;

      label {
        padding-top: 5px;
        padding-right: 20px;
        text-align: left;
      }
    }

    .flex-3-2 {
      > *:first-child {
        flex: 1;
      }

      > *:last-child {
        flex: 1;
      }
    }

    .flex-2-3 {
      > *:first-child {
        flex: 2;
      }

      > *:last-child {
        flex: 3;
      }
    }

    .flex-1-5 {
      > *:first-child {
        flex: 1;
      }

      > *:last-child {
        flex: 5;
      }
    }
  }
}
