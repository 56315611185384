$flag-size: 48px;

footer {
  height: 60px;
  background-color: $dusty-black-400;

  a {
    display: inline-block;
    line-height: $flag-size;
    padding: 0 5px;
  }

  /* Overriding addon styles */
  .locale-flags {
    display: inline-block;
    margin-bottom: 0;

    &__flag {
      display: inline-block;
      width: $flag-size;
    }
  }
}
